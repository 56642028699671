import React from 'react';
import { Helmet } from "react-helmet";

const Footprint = () => {
  return (
    <>
    <Helmet>
<title>Global Footprint | HBL People</title>
<meta
name="description"
content="A Global community working towards a Common Purpose."
/>
</Helmet>
      <section className="innerpagebanner footprint">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top left 0.3s and move 65px wait .3s">GLOBAL FOOTPRINT</h1>
      <h2 data-sr="enter top left 0.3s and move 65px wait .6s">A Global community working towards a Common Purpose.</h2>
    </div>
  </div>
</section>
<section className="innersection footprint">
  <div className="container">
    <div className="text-center mb-5">
      <h3 className="web" data-sr="enter top left 0.3s and move 65px wait .3s">TAKE THE NEXT STEP</h3>
      <h2 className="web" data-sr="enter top left 0.3s and move 65px wait .6s">Build your career the way you want</h2>
      <p className="mb-2" data-sr="enter top left 0.3s and move 65px wait .9s">Embarking on a progressive journey, HBL started its international operations by opening the Bank’s first international branch in Colombo, Sri Lanka in 1951. As we continue to grow and expand, today the bank operates in 12 countries.</p>
      <p data-sr="enter top left 0.3s and move 65px wait 1.2s">A melting pot of the finest resources, HBL employs people from over 25 nationalities from across the world.</p>
    </div>
    <div className="row align-items-center">
      <div className="col-lg-7"> <img src="images/footprint/map.png" className="img-fluid" alt=""/> </div>
      <div className="col-lg-5">
        <div className="accordion footprint" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> ASIA &amp; THE PACIFIC </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <ul>
                  <li><img src="images/footprint/bangladesh.png" alt=""/> Bangladesh</li>
                  <li><img src="images/footprint/china.png" alt=""/> China</li>
                  <li><img src="images/footprint/maldives.png" alt=""/> Maldives</li>
                  <li><img src="images/footprint/pakistan.png" alt=""/> Pakistan</li>
                  <li><img src="images/footprint/singapore.png" alt=""/> Singapore</li>
                  <li><img src="images/footprint/sri_lanka.png" alt=""/> Sri Linka</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> THE MIDDLE EAST </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <ul>
                  <li><img src="images/footprint/bahrain.png" alt=""/> Bahrain</li>
                  <li><img src="images/footprint/united_arab_emirates.png" alt=""/> UAE</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> EUROPE </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <ul>
                  <li><img src="images/footprint/belgium.png" alt=""/> Belgium</li>
                  <li><img src="images/footprint/turkey.png" alt=""/> Turkey</li>
                  <li><img src="images/footprint/united_kingdom.png" alt=""/> United Kingdom</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"> AFRICA </button>
            </h2>
            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <ul>
                  <li><img src="images/footprint/mauritius.png" alt=""/> Mauritius</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </>
  )
}

export default Footprint
