import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import Experienced from "./pages/Experienced";
import Graduates from "./pages/graduates";
import Benefits from "./pages/Benefits";
import Culture from "./pages/Culture";
import Impact from "./pages/Impact";
import Diversity from "./pages/Diversity";
import Learning from "./pages/Learning";
import Footprint from "./pages/Footprint";
import Function from "./pages/Function";
import Disclaimer from "./pages/Disclaimer";
import Privacypolicy from "./pages/privacy-policy";
// function detail
import Functionretailbanking from "./pages/function_details/Function-retail-banking";
import Functionfinancialinstitutionsandglobalremittancesbusiness from "./pages/function_details/Function-financial-institutions-and-global-remittances-business";
import Functionconsumeragriculture from "./pages/function_details/Function-consumer-agriculture";
import Functioncorporateandinvestmentbanking from "./pages/function_details/Function-corporate-and-investment-banking";
import Functioncorporatesecretariat from "./pages/function_details/Function-corporate-secretariat";
import Functioncorporatestrategy from "./pages/function_details/Function-corporate-strategy";
import Functiondevelopmentfinance from "./pages/function_details/Function-development-finance";
import Functionfinance from "./pages/function_details/Function-finance";
import Functionglobalcomplianceconduct from "./pages/function_details/Function-global-compliance-conduct";
import Functionhumanresources from "./pages/function_details/Function-human-resources";
import Functioninformationsecurity from "./pages/function_details/Function-information-security";
import Functioninformationtechnology from "./pages/function_details/Function-information-technology";
import Functioninnovationfinancialinclusion from "./pages/function_details/Function-innovation-financial-inclusion";
import Functioninternalaudit from "./pages/function_details/Function-internal-audit";
import Functioninternationalbanking from "./pages/function_details/Function-international-banking";
import Functionislamicbanking from "./pages/function_details/Function-islamic-banking";
import Functionlegalaffairs from "./pages/function_details/Function-legal-affairs";
import Functionmarketingcorporateaffairs from "./pages/function_details/Function-marketing-corporate-affairs";
import Functioncoofunctions from "./pages/function_details/Function-coo-functions";
import Functionremedialstructuredcredit from "./pages/function_details/Function-remedial-structured-credit";
import Functiontreasuryglobalmarkets from "./pages/function_details/Function-treasury-global-markets";
import Functionriskmanagement from "./pages/function_details/Function-risk-management";
import Leaguehome from "./pages/the-league/Home";
import Leagueinternship from "./pages/the-league/Internship";
import Managementtrainee from "./pages/the-league/Management-trainee";
import Leaguehblexperience from "./pages/the-league/The-hbl-experience";
import Leagueyourrole from "./pages/the-league/Your-role";
import Leaguelifeintheleague from "./pages/the-league/Life-in-the-league";
import Leagueyourcareerpath from "./pages/the-league/Your-career-path";
import Leaguelookfor from "./pages/the-league/What-do-we-look-for";
import Leagueprocess from "./pages/the-league/Application-process-faqs";
import Leagueagp from "./pages/the-league/Accelerate-graduate-programme";
import Leagueagphblexperience from "./pages/the-league/Agp-the-hbl-experience";
import Leagueagpyourrole from "./pages/the-league/Agp-your-role";
import Leagueagplifeintheleague from "./pages/the-league/Agp-life-in-the-league";
import Leagueagpyourcareerpath from "./pages/the-league/Agp-your-career-path";
import Leagueagplookfor from "./pages/the-league/Agp-what-do-we-look-for";
import Leagueagpprocess from "./pages/the-league/Agp-application-process-faqs";
// internship post
import Intpost1 from "./pages/the-league/posts/internship/Intpost1";
import Intpost2 from "./pages/the-league/posts/internship/Intpost2";
import Intpost3 from "./pages/the-league/posts/internship/Intpost3";
import Intpost4 from "./pages/the-league/posts/internship/Intpost4";
import Intpost5 from "./pages/the-league/posts/internship/Intpost5";
import Intpost6 from "./pages/the-league/posts/internship/Intpost6";
import Intpost7 from "./pages/the-league/posts/internship/Intpost7";
import Intpost8 from "./pages/the-league/posts/internship/Intpost8";
import Intpost9 from "./pages/the-league/posts/internship/Intpost9";
import Intpost10 from "./pages/the-league/posts/internship/Intpost10";
import Intpost11 from "./pages/the-league/posts/internship/Intpost11";
import Intpost12 from "./pages/the-league/posts/internship/Intpost12";
import Intpost13 from "./pages/the-league/posts/internship/Intpost13";
// internship post
// campus post
import Campost23 from "./pages/the-league/posts/campus/Campost23";
import Campost24 from "./pages/the-league/posts/campus/Campost24";
import Campost25 from "./pages/the-league/posts/campus/Campost25";
import Campost26 from "./pages/the-league/posts/campus/Campost26";
import Campost27 from "./pages/the-league/posts/campus/Campost27";
import Campost28 from "./pages/the-league/posts/campus/Campost28";
import Campost29 from "./pages/the-league/posts/campus/Campost29";
import Campost30 from "./pages/the-league/posts/campus/Campost30";
import Campost31 from "./pages/the-league/posts/campus/Campost31";
import Campost32 from "./pages/the-league/posts/campus/Campost32";
import Campost33 from "./pages/the-league/posts/campus/Campost33";
import Campost34 from "./pages/the-league/posts/campus/Campost34";
import Campost35 from "./pages/the-league/posts/campus/Campost35";
import Campost36 from "./pages/the-league/posts/campus/Campost36";
import Campost37 from "./pages/the-league/posts/campus/Campost37";
import Campost38 from "./pages/the-league/posts/campus/Campost38";
import Campost39 from "./pages/the-league/posts/campus/Campost39";
import Campost40 from "./pages/the-league/posts/campus/Campost40";
import Campost41 from "./pages/the-league/posts/campus/Campost41";
import Campost42 from "./pages/the-league/posts/campus/Campost42";

import Layout2 from "./pages/Layout2";
// campus post
import { RemoveTrailingSlash } from "./components/RemoveTrailingSlash";

// import RedirectLinks from "./pages/Redirectlinks";
// import reportWebVitals from './reportWebVitals';

export default function App() {

  return (
    <>

      <Router>
        <RemoveTrailingSlash />
        {/* <RedirectLinks/> */}
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/home/01-2" element={<Navigate to="/" replace />} />
            <Route path="/experienced-professionals" element={<Experienced />} />
            <Route path="/fresh-graduates" element={<Graduates />} />
            <Route path="/graduates-and-trainees" element={<Navigate to="/fresh-graduates" replace />} />
            <Route path="/benefits" element={<Benefits />} />
            <Route path="/culture" element={<Culture />} />
            <Route path="/impact" element={<Impact />} />
            <Route path="/diversity" element={<Diversity />} />
            <Route path="/learning" element={<Learning />} />
            <Route path="/learning-development" element={<Navigate to="/learning" replace />} />
            <Route path="/footprint" element={<Footprint />} />
            <Route path="/global-footprint" element={<Navigate to="/footprint" replace />} />
            <Route path="/function" element={<Function />} />
            <Route path="/divisions" element={<Navigate to="/function" replace />} />
            <Route path="/all-divisions" element={<Navigate to="/function" replace />} />
            <Route path="/function-retail-banking" element={<Functionretailbanking />} />
            <Route path="/function-financial-institutions-and-global-remittances-business" element={<Functionfinancialinstitutionsandglobalremittancesbusiness />} />
            <Route path="/function-consumer-agriculture" element={<Functionconsumeragriculture />} />
            <Route path="/function-corporate-and-investment-banking" element={<Functioncorporateandinvestmentbanking />} />
            <Route path="/function-corporate-secretariat" element={<Functioncorporatesecretariat />} />
            <Route path="/function-corporate-strategy" element={<Functioncorporatestrategy />} />
            <Route path="/function-development-finance" element={<Functiondevelopmentfinance />} />
            <Route path="/function-finance" element={<Functionfinance />} />
            <Route path="/function-global-compliance-conduct" element={<Functionglobalcomplianceconduct />} />
            <Route path="/function-human-resources" element={<Functionhumanresources />} />
            <Route path="/function-information-security" element={<Functioninformationsecurity />} />
            <Route path="/function-information-technology" element={<Functioninformationtechnology />} />
            <Route path="/function-innovation-financial-inclusion" element={<Functioninnovationfinancialinclusion />} />
            <Route path="/function-internal-audit" element={<Functioninternalaudit />} />
            <Route path="/function-international-banking" element={<Functioninternationalbanking />} />
            <Route path="/function-islamic-banking" element={<Functionislamicbanking />} />
            <Route path="/function-legal-affairs" element={<Functionlegalaffairs />} />
            <Route path="/function-marketing-corporate-affairs" element={<Functionmarketingcorporateaffairs />} />
            <Route path="/function-operation-services" element={<Functioncoofunctions />} />
            <Route path="/function-remedial-structured-credit" element={<Functionremedialstructuredcredit />} />
            <Route path="/function-risk-management" element={<Functionriskmanagement />} />
            <Route path="/function-treasury-global-markets" element={<Functiontreasuryglobalmarkets />} />
            {/* redirect */}
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/privacy-policy" element={<Privacypolicy />} />
            <Route path="*" element={<NoPage />} />
          </Route>
          <Route path="/" element={<Layout2 />}>
            <Route path="/the-league/privacy-policy" element={<Privacypolicy />} />
            <Route path="/the-league" element={<Leaguehome />} />
            <Route path="/the-league/internship" element={<Leagueinternship />} />
            <Route path="/the-league/management-trainee" element={<Managementtrainee />} />
            <Route path="/the-league/the-hbl-experience" element={<Leaguehblexperience />} />
            <Route path="/the-league/your-role" element={<Leagueyourrole />} />
            <Route path="/the-league/life-in-the-league" element={<Leaguelifeintheleague />} />
            <Route path="/the-league/your-career-path" element={<Leagueyourcareerpath />} />
            <Route path="/the-league/what-do-we-look-for" element={<Leaguelookfor />} />
            <Route path="/the-league/application-process-faqs" element={<Leagueprocess />} />
            <Route path="/the-league/accelerate-graduate-programme" element={<Leagueagp />} />
            <Route path="/the-league/agp-the-hbl-experience" element={<Leagueagphblexperience />} />
            <Route path="/the-league/agp-your-role" element={<Leagueagpyourrole />} />
            <Route path="/the-league/agp-life-in-the-league" element={<Leagueagplifeintheleague />} />
            <Route path="/the-league/agp-your-career-path" element={<Leagueagpyourcareerpath />} />
            <Route path="/the-league/agp-what-do-we-look-for" element={<Leagueagplookfor />} />
            <Route path="/the-league/agp-application-process-faqs" element={<Leagueagpprocess />} />
            <Route path="/the-league/lunch-learn-event-for-summer-interns-2017" element={<Intpost1 />} />
            <Route path="/the-league/scavenger-hunt-for-summer-interns-2017" element={<Intpost2 />} />
            <Route path="/the-league/summer-internship-2017-closing-ceremony" element={<Intpost3 />} />
            <Route path="/the-league/summer-internship-2017-orientation-ceremony" element={<Intpost4 />} />
            <Route path="/the-league/the-league-orientation-ceremony-2018" element={<Intpost5 />} />
            <Route path="/the-league/team-bonding" element={<Intpost6 />} />
            <Route path="/the-league/beach-cleaning-karachi" element={<Intpost7 />} />
            <Route path="/the-league/book-donation-islamabad" element={<Intpost8 />} />
            <Route path="/the-league/closing" element={<Intpost9 />} />
            <Route path="/the-league/lunch-with-ted" element={<Intpost10 />} />
            <Route path="/the-league/the-league-orientation-ceremony-2019" element={<Intpost11 />} />
            <Route path="/the-league/mt-and-accelerate-graduates-orientation-ld-2021" element={<Intpost12 />} />
            <Route path="/the-league/internship-orientation-2021" element={<Intpost13 />} />
            <Route path="/the-league/assessment-centre-lahore-2019" element={<Campost23 />} />
            <Route path="/the-league/assessment-centre-karachi-2019" element={<Campost24 />} />
            <Route path="/the-league/lums-campus-drive-2019" element={<Campost25 />} />
            <Route path="/the-league/nust-campus-drive-2019" element={<Campost26 />} />
            <Route path="/the-league/lse-campus-drive-2019" element={<Campost27 />} />
            <Route path="/the-league/the-league-team-building-bootcamp-2019" element={<Campost28 />} />
            <Route path="/the-league/the-league-orientation-ceremony-2019" element={<Campost29 />} />
            <Route path="/the-league/gik-campus-drive-2020" element={<Campost30 />} />
            <Route path="/the-league/habib-university-campus-drive-2020" element={<Campost31 />} />
            <Route path="/the-league/lse-campus-drive-2020" element={<Campost32 />} />
            <Route path="/the-league/lums-campus-drive-2020" element={<Campost33 />} />
            <Route path="/the-league/nust-campus-drive" element={<Campost34 />} />
            <Route path="/the-league/iba-career-fair-2020" element={<Campost35 />} />
            <Route path="/the-league/iobm-career-fair-2020" element={<Campost36 />} />
            <Route path="/the-league/iba-campus-drive-2020" element={<Campost37 />} />
            <Route path="/the-league/iobm-campus-drive-2020" element={<Campost38 />} />
            <Route path="/the-league/mt-and-accelerate-graduate-orientation-2021" element={<Campost39 />} />
            <Route path="/the-league/cohort-2021-karachi-get-together" element={<Campost40 />} />
            <Route path="/the-league/the-league-2021-meet-greet" element={<Campost41 />} />
            <Route path="/the-league/iba-campus-drive-2022" element={<Campost42 />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// reportWebVitals();
